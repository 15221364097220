.sidenav {
  // scss-docs-start sidenav-css-vars
  --#{$prefix}sidenav-transform: #{$sidenav-transform};
  --#{$prefix}sidenav-zindex: #{$sidenav-zindex};
  --#{$prefix}sidenav-background-color: #{$sidenav-background-color};
  --#{$prefix}sidenav-width: #{$sidenav-width};
  --#{$prefix}sidenav-height: #{$sidenav-height};
  --#{$prefix}sidenav-box-shadow: #{$sidenav-box-shadow};
  --#{$prefix}sidenav-data-hidden-false-transform: #{$sidenav-data-hidden-false-transform};
  --#{$prefix}sidenav-data-color-light-color: #{$sidenav-data-color-light-color};
  --#{$prefix}sidenav-data-right-true-transform: #{$sidenav-data-right-true-transform};
  --#{$prefix}sidenav-data-slim-collapsed-true-width: #{$sidenav-data-slim-collapsed-true-width};
  --#{$prefix}sidenav-menu-padding: #{$sidenav-menu-padding};
  --#{$prefix}sidenav-collapse-sidenav-link-font-size: #{$sidenav-collapse-sidenav-link-font-size};
  --#{$prefix}sidenav-collapse-sidenav-link-height: #{$sidenav-collapse-sidenav-link-height};
  --#{$prefix}sidenav-link-font-size: #{$sidenav-link-font-size};
  --#{$prefix}sidenav-link-padding-y: #{$sidenav-link-padding-y};
  --#{$prefix}sidenav-link-padding-x: #{$sidenav-link-padding-x};
  --#{$prefix}sidenav-link-height: #{$sidenav-link-height};
  --#{$prefix}sidenav-link-border-radius: #{$sidenav-link-border-radius};
  --#{$prefix}sidenav-link-transition: #{$sidenav-link-transition};
  --#{$prefix}sidenav-link-hover-background-color: #{$sidenav-link-hover-background-color};
  --#{$prefix}sidenav-link-active-focus-background-color: #{$sidenav-link-active-focus-background-color};
  --#{$prefix}sidenav-link-active-color: #{$sidenav-link-active-color};
  --#{$prefix}sidenav-subheading-font-size: #{$sidenav-subheading-font-size};
  --#{$prefix}sidenav-subheading-padding-y: #{$sidenav-subheading-padding-y};
  --#{$prefix}sidenav-subheading-padding-x: #{$sidenav-subheading-padding-x};
  --#{$prefix}sidenav-subheading-fw: #{$sidenav-subheading-fw};
  --#{$prefix}sidenav-sm-link-pt: #{$sidenav-sm-link-pt};
  --#{$prefix}sidenav-sm-link-pb: #{$sidenav-sm-link-pb};
  --#{$prefix}sidenav-rotate-icon-margin-right: #{$sidenav-rotate-icon-margin-right};
  --#{$prefix}sidenav-rotate-icon-transition: #{$sidenav-rotate-icon-transition};
  --#{$prefix}sidenav-light-color: #{$sidenav-light-color};
  // scss-docs-end sidenav-css-vars

  top: 0;
  left: 0;
  transform: var(--#{$prefix}sidenav-transform);
  position: fixed;
  z-index: var(--#{$prefix}sidenav-zindex);
  background-color: var(--#{$prefix}sidenav-background-color);
  overflow: hidden;
  width: var(--#{$prefix}sidenav-width);
  height: var(--#{$prefix}sidenav-height);
  box-shadow: var(--#{$prefix}sidenav-box-shadow);
  // Additional MDB React styles
  transition: all 0.3s linear 0s;
  // Additional MDB React styles

  // &[data-mdb-hidden='false'] {
  //   transform: var(--#{$prefix}sidenav-data-hidden-false-transform);
  // }

  // &[data-mdb-color='light'] {
  //   color: var(--#{$prefix}sidenav-data-color-light-color);
  // }

  // &[data-mdb-right='true'] {
  //   right: 0;
  //   left: unset;
  //   transform: var(--#{$prefix}sidenav-data-right-true-transform);
  // }

  // &[data-mdb-position='absolute'] {
  //   position: absolute;
  //   height: 100%;
  // }

  // &[data-mdb-position='relative'] {
  //   position: relative;
  //   height: 100%;
  // }

  // [data-mdb-slim='true'] {
  //   display: none;
  // }

  // &[data-mdb-slim-collapsed='true'] {
  //   width: var(--#{$prefix}sidenav-data-slim-collapsed-true-width);

  //   [data-mdb-slim='false'] {
  //     display: none;
  //   }

  //   [data-mdb-slim='true'] {
  //     display: unset;
  //   }
  // }

  // Additional MDB React styles
  &.sidenav-shown {
    transform: translateX(0%) !important;
  }

  &.sidenav-hidden {
    transform: $sidenav-transform;
  }

  &.sidenav-theme-light {
    color: $sidenav-data-color-light-color;
  }

  &.sidenav-right {
    right: 0;
    left: unset;
    transform: $sidenav-data-right-true-transform;
  }

  &.sidenav-absolute {
    position: absolute;
    height: 100%;
  }

  &.sidenav-relative {
    position: relative;
    height: 100%;
  }

  .sidenav-slim {
    display: none;
  }

  &.sidenav-slim-collapsed {
    width: $sidenav-data-slim-collapsed-true-width;

    .sidenav-non-slim {
      display: none;
    }

    .sidenav-slim {
      display: unset;
    }
  }
  // Additional MDB React styles
}

.sidenav-menu,
.sidenav-collapse {
  list-style: none;
  position: relative;
  padding: 0 var(--#{$prefix}sidenav-menu-padding);
  margin: 0;
}

.sidenav-collapse {
  display: none;
  padding: 0;

  &.show,
  &.collapsing {
    display: block;
  }

  .sidenav-link {
    font-size: var(--#{$prefix}sidenav-collapse-sidenav-link-font-size);
    height: var(--#{$prefix}sidenav-collapse-sidenav-link-height);
    padding-left: 3.4rem;
  }
}

.sidenav-item {
  position: relative;
}

.sidenav-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: var(--#{$prefix}sidenav-link-font-size);
  padding: var(--#{$prefix}sidenav-link-padding-y) var(--#{$prefix}sidenav-link-padding-x);
  height: var(--#{$prefix}sidenav-link-height);
  color: unset;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: var(--#{$prefix}sidenav-link-border-radius);
  transition: var(--#{$prefix}sidenav-link-transition);

  &:hover {
    color: inherit;
    background-color: var(--#{$prefix}sidenav-link-hover-background-color);
    outline: none;
  }

  &:active,
  &:focus {
    color: inherit;
    background-color: var(--#{$prefix}sidenav-link-active-focus-background-color);
    outline: none;
  }

  &.active {
    color: var(--#{$prefix}sidenav-link-active-color);
  }

  i {
    color: #9fa6b2;
  }
}

.sidenav-subheading {
  color: unset;
  text-transform: uppercase;
  font-size: var(--#{$prefix}sidenav-subheading-font-size);
  padding: var(--#{$prefix}sidenav-subheading-padding-y) var(--#{$prefix}sidenav-subheading-padding-x);
  font-weight: var(--#{$prefix}sidenav-subheading-fw);
}

.sidenav-sm {
  .sidenav-link {
    padding-top: var(--#{$prefix}sidenav-sm-link-pt);
    padding-bottom: var(--#{$prefix}sidenav-sm-link-pb);
    height: initial;
  }
}

.rotate-icon {
  position: absolute;
  right: 0;
  margin-left: auto;
  margin-right: var(--#{$prefix}sidenav-rotate-icon-margin-right);
  transition: var(--#{$prefix}sidenav-rotate-icon-transition);
}

.sidenav-backdrop {
  --#{$prefix}sidenav-backdrop-zindex: #{$sidenav-backdrop-zindex};
  --#{$prefix}sidenav-backdrop-background-color: #{$sidenav-backdrop-background-color};

  z-index: var(--#{$prefix}sidenav-backdrop-zindex);
  top: 0;
  left: 0;
  background-color: var(--#{$prefix}sidenav-backdrop-background-color);
}

.sidenav-light {
  color: var(--#{$prefix}sidenav-light-color);
}

// Color options

@each $color, $value in $theme-colors {
  $red: red($value);
  $green: green($value);
  $blue: blue($value);

  .sidenav-#{$color} {
    .sidenav-item {
      .sidenav-link {
        &:hover {
          color: inherit;
          background-color: rgba($red, $green, $blue, 0.05);
        }
      }
    }

    .sidenav-link {
      &:active,
      &:focus {
        color: inherit;
        background-color: rgba($red, $green, $blue, 0.05);
      }

      &.active {
        color: inherit;
      }
    }
  }
}

// Additional MDB React styles
.sidenav-content {
  transition: all 0.3s ease;
}

.push-shown {
  padding: 20px 20px 20px 260px;
  margin-right: -240px;
}

.right-push-shown {
  padding: 20px 260px 20px 20px;
  margin-left: -240px;
}

.push-hidden {
  padding: 20px;
  margin-right: 0px;
}

.side-shown {
  padding: 0 0 20px 260px;
  margin-right: 0px;
}

.right-side-shown {
  padding: 20px 260px 20px 20px;
  margin-right: 0px;
}

.side-hidden {
  padding: 20px;
  margin-right: 0px;
}
// Additional MDB React styles




//// OLD SCSS

//.sidenav {
//  top: 0;
//  left: 0;
//  position: fixed;
//  z-index: $zindex-sidenav;
//  background-color: #FFFFFF !important;
//  color: #000000 !important;
//  overflow: hidden;
//  width: $sidenav-width;
//  height: $sidenav-height;
//  transition: all 0.3s linear 0s;
//  box-shadow: $sidenav-box-shadow;
//
//  &.sidenav-shown {
//    transform: translateX(0%) !important;
//  }
//
//  &.sidenav-hidden {
//    transform: $sidenav-transform;
//  }
//
//  &.sidenav-theme-light {
//    color: $sidenav-data-color-light-color;
//  }
//
//  &.sidenav-right {
//    right: 0;
//    left: unset;
//    transform: $sidenav-data-right-true-transform;
//  }
//
//  &.sidenav-absolute {
//    position: fixed;
//    height: 100%;
//    overflow: auto;
//    resize: both;
//  }
//
//  &.sidenav-relative {
//    position: relative;
//    height: 100%;
//  }
//
//  .sidenav-slim {
//    display: none;
//  }
//
//  &.sidenav-slim-collapsed {
//    width: $sidenav-data-slim-collapsed-true-width;
//
//    .sidenav-non-slim {
//      display: none;
//    }
//
//    .sidenav-slim {
//      display: unset;
//    }
//  }
//}

.sidenav-menu,
.sidenav-collapse {
  list-style: none;
  position: relative;
  padding: 0;
  margin: 0;
}

.sidenav-collapse {
  display: none;
  padding: 0;

  &.show,
  &.collapsing {
    display: block;
  }

  .sidenav-link {
    font-family: Montserrat, sans-serif !important;
    font-size: $sidenav-collapse-sidenav-link-font-size;
    height: $sidenav-collapse-sidenav-link-height;
    padding-left: 0;
    font-family: $font-family-base;

  }
}

.sidenav-link {
  font-family: Montserrat, sans-serif !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: $sidenav-link-font-size;
  padding: 2rem $sidenav-link-padding-x;
  height: $sidenav-link-height;
  color: unset;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 0;
  transition: $sidenav-link-transition;
  border-top: 1px $black solid;
}


.sidenav-item {
  position: relative;

  &.dashboard {
    border-left: 10px $clBlue solid;

    .sidenav-link {
       &:hover {
        color: $p1;
        background-color: $p1Pastel;
      }
    }
  }

  &.companies {
    border-left: 10px $clGreen solid;

    .sidenav-link {
      &:hover {
        color: $p4;
        background-color: $p4Pastel;
      }
    }
  }

  &.teams {
    border-left: 10px $clOrange solid;

    .sidenav-link {
      &:hover {
        color: $p3;
        background-color: $p3Pastel;
      }
    }
  }

  &.surveys {
    border-left: 10px $clRed solid;

    .sidenav-link {
      &:hover {
        color: $p2;
        background-color: $p2Pastel;
      }
    }

  }
}


.rotate-icon {
  position: absolute;
  right: 0;
  margin-left: auto;
  margin-right: $sidenav-rotate-icon-margin-right;
  transition: $sidenav-rotate-icon-transition;
}

//.sidenav-backdrop {
//  z-index: $zindex-sidenav-backdrop;
//  top: 0;
//  left: 0;
//  background-color: $sidenav-backdrop-background-color;
//}

.sidenav-light {
  color: $sidenav-light-color;
}

//// Color options
//
//@each $color, $value in $theme-colors {
//  $red: red($value);
//  $green: green($value);
//  $blue: blue($value);
//
//  .sidenav-#{$color} {
//    .sidenav-item {
//      .sidenav-link {
//        // text-transform: uppercase;
//        &:hover {
//          color: $value;
//          background-color: rgba($red, $green, $blue, 0.05);
//        }
//      }
//    }
//
//    .sidenav-link {
//      &:active,
//      &:focus {
//        color: $value;
//        background-color: rgba($red, $green, $blue, 0.05);
//      }
//
//      &.active {
//        color: $value;
//      }
//    }
//  }
//}

.sidenav-content {
  transition: all 0.3s ease;
}

.push-shown {
  padding: 0 20px 20px 240px;
  margin-right: -240px;
}

.right-push-shown {
  padding: 20px 240px 20px 0;
  margin-left: -240px;
}

.push-hidden {
  padding: 0;
  margin-right: 0;
}

.side-shown {
  padding: 0 0 20px 240px;
  margin-right: 0;
}

.right-side-shown {
  padding: 20px 240px 20px 0;
  margin-right: 0;
}

.side-hidden {
  padding: 0;
  margin-right: 0;
}

.sidenav-header {
  display: flex;
  background-color: $white;
  padding: 15px 20px 20px 20px;
  height: 160px;
  max-height: 160px;
  justify-content: center;
}
