// Variable overrides

// Font.
$font-family-base: Montserrat, sans-serif !important;
$font: (
    family:	(Montserrat, sans-serif),
    weight:	400,
    style: normal,
);

$font-family-myriad: 'Myriad Pro', sans-serif;
$font-family-hypatia: hypatia-sans-pro, sans-serif;
$input-font-family: Montserrat, sans-serif !important;

// Palette.
$bg:	#F9F8F7;
$clBlueOld: #0082ca;
$clBlue: #0082c2;
$clRedOld: #c11b20;
$clRed: #ce132e;
$clYellow: #ffdd00;
$clOrangeOld: #f08019;
$clOrange: #f0801a;
$clGreenOld: #93d500;
$clGreen: #95c11f;
$clPurple: #773dbd;
$clPink: #f478c4;
$clGrey: #6f7271;
$pageBackground: #eef1f7;
$lightGrey: #D8D8D8;
$shadowColor: rgba(0, 0, 0, 0.10);
$shadow:	rgba(0,0,0,0.10);
$reportText: #4F4F4F;

$p1: #007fc1; // blue
$p2: #cd1423; // red
$p3: #f6a000; // orange
$p4: #9cbf1d; // green
$p5: #e07aad; // pink
$p6: #643c8c; // purple

//$p1Pastel: rgba(246, 160, 0, 0.15);
//$p2Pastel: rgba(0, 127, 193, 0.15);
//$p3Pastel: rgba(205, 20, 35, 0.15);
//$p4Pastel: rgba(156, 191, 29, 0.15);
//$p5Pastel: rgba(224, 122, 173, 0.15);
//$p6Pastel: rgba(100, 60, 140, 0.15);
$p1Pastel: rgba(0, 127, 193, 0.15);
$p2Pastel: rgba(205, 20, 35, 0.15);
$p3Pastel: rgba(246, 160, 0, 0.15);
$p4Pastel: rgba(156, 191, 29, 0.15);
$p5Pastel: rgba(224, 122, 173, 0.15);
$p6Pastel: rgba(100, 60, 140, 0.15);

$good: rgba(156, 191, 29, 0.4);
$bad: rgba(205, 20, 35, 0.4);
$average: rgba(246, 160, 0, 0.4);

$bronze: rgb(128, 81, 31);
$silver: rgb(101, 101, 101);
$gold: rgb(156, 132, 1);

$bronzeBg: rgba(205, 127,50, 0.4);
$silverBg: rgba(192, 192, 192, 0.4);
$goldBg: rgba(255,215, 0, 0.4);

// Sidenav

//$sidenav-transform: translateX(-100%) !default;
//$sidenav-background-color: $clPurple !default;
//$sidenav-width: 240px !default;
//$sidenav-height: 100vh !default;
//$sidenav-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.05) !default;
//
//$sidenav-data-hidden-false-transform: translateX(0%) !default;
//$sidenav-data-color-light-color: rgba(255, 255, 255, 0.6) !default;
//$sidenav-data-right-true-transform: translateX(100%) !default;
//$sidenav-data-slim-collapsed-true-width: 70px !default;
//
//$sidenav-menu-padding: 0.2rem !default;
//
//$sidenav-collapse-sidenav-link-font-size: 0.78rem !default;
//$sidenav-collapse-sidenav-link-height: 1.5rem !default;
//$sidenav-collapse-sidenav-link-padding-left: 3rem !default;
//
//$sidenav-link-font-size: 0.89rem !default !default;
//$sidenav-link-padding-y: 1.5rem !default;
//$sidenav-link-padding-x: 1.5rem !default;
//$sidenav-link-height: 3rem !default;
//$sidenav-link-border-radius: 5px !default;
//$sidenav-link-transition: all 0.3s linear !default;

//$sidenav-link-hover-color: $clGrey !default;
//$sidenav-link-hover-background-color: #f6f9fc !default;

//$sidenav-link-active-focus-color: $sidenav-link-hover-color !default;
//$sidenav-link-active-focus-background-color: $sidenav-link-hover-background-color !default;
//$sidenav-link-active-color: $sidenav-link-hover-color !default;

//$sidenav-rotate-icon-margin-right: 0.8rem !default;
//$sidenav-rotate-icon-transition: transform 0.3s !default;
//
//$sidenav-backdrop-background-color: rgba(0, 0, 0, 0.1) !default;
//
//$sidenav-light-color: $sidenav-data-color-light-color !default;

// datatables

$datatable-font-size: 0.9rem !default;
$datatable-background-color: $white !default;
$datatable-table-th-td-max-width: 250px !default;
$datatable-table-th-td-padding-y: 1rem !default;
$datatable-table-th-td-padding-x: 1rem !default;
$datatable-thead-tr-border-width: 1px !default;
$datatable-thead-th-font-weight: 700 !default;
$datatable-thead-fixed-cell-background-color: $white !default;

$datatable-tbody-font-weight: 500 !important;
$datatable-tbody-tr-transition: all 0.3s ease-in !default;
$datatable-tbody-tr-last-child-height: 71px !default;
$datatable-tbody-loader-height: 2px !default;

$datatable-tbody-progress-animation: datatableProgress 3s ease-in-out !default;
$datatable-tbody-progress-width: 45% !default;
$datatable-tbody-progress-opacity: 0.5 !default;
$datatable-tbody-progress-border-radius: 1px !default;

$datatable-pagination-padding-y: 0.5rem !default;
$datatable-pagination-border-width: 1px !default;
$datatable-pagination-nav-font-size: 0.9rem !default;
$datatable-pagination-buttons-margin-left: 2rem !default;
$datatable-pagination-button-padding-x: 1rem !default;

$datatable-sort-icon-transition-duration: 0.3s !default;
$datatable-sort-icon-left: 0.4rem !default;
$datatable-sort-icon-top: calc(50% - 0.5rem) !default;

$datatable-select-wrapper-font-size: 0.9rem !default;
$datatable-select-wrapper-font-weight: 500 !default;

$datatable-select-font-size: 1rem !default;
$datatable-select-line-height: 1.3 !default;
$datatable-select-padding-top: 0.4em !default;
$datatable-select-padding-right: 1.2em !default;
$datatable-select-padding-bottom: 0.3em !default;
$datatable-select-padding-left: 0.6em !default;
$datatable-select-margin-right: 1.4rem !default;
$datatable-select-margin-left: 0.1rem !default;
$datatable-select-background: url("data:image/svg+xml !default;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23000'><polygon points='0,0 100,0 50,50'/></svg>") !default;
$datatable-select-background-position: right 0.4em top 60%, 0 0 !default;
$datatable-select-background-size: 0.65em auto, 100% !default;
$datatable-select-focus-font-weight: 500 !default;
$datatable-select-option-color: $black !default;

$datatable-sm-th-td-padding-y: 0.5rem !default;
$datatable-sm-th-td-padding-x: 1.4rem !default;
$datatable-sm-tbody-tr-last-child-height: 55px !default;
$datatable-sm-pagination-padding: 0.2rem !default;

$datatable-dark-check-border-color: $white !default;
$datatable-dark-select-background: url("data:image/svg+xml !default;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='white'><polygon points='0,0 100,0 50,50'/></svg>") !default;
$datatable-dark-datatable-progress-opacity: 0.8 !default;

$datatable-hover-tbody-tr-transition: background-color 0.2s ease-in !default;
$datatable-bordered-th-td-border-width: 1px !default;
$datatable-loading-select-background: url("data:image/svg+xml !default;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='grey'><polygon points='0,0 100,0 50,50'/></svg>") !default;


$btn-font-weight: $font-weight-medium !default;
$btn-box-shadow: 0 4px 9px -4px rgba($black, 0.35) !default;
$btn-hover-box-shadow: 0 8px 9px -4px rgba($black, 0.15), 0 4px 18px 0 rgba($black, 0.1) !default;
$btn-focus-box-shadow: $btn-hover-box-shadow !default;
$btn-active-box-shadow: $btn-focus-box-shadow !default;
