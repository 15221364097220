.navbar {

  height: 320px;
  max-height: 320px;

  &.dashboard {
    background-image: url('../../assets/img/dashboard-header-image.jpg');
  }

  &.companies {
    background-image: url('../../assets/img/companies-header-image.jpg');
  }

  &.teams {
    background-image: url('../../assets/img/teams-header-image.jpg');
  }

  &.reports {
    background-image: url('../../assets/img/reports-header-image.jpg');
  }

  &.surveys {
    background-image: url('../../assets/img/surveys-header-image.jpg');
  }

  &.navbar-scroll {
    transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
    &.navbar-scrolled {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  p {
    color: #000;
  }

  .clutterbuck {
    color: #fff !important;
  }

  .navbar-toggler {
    color: #FFF !important;
  }
  .nav-link {
    font-family: $font-family-hypatia;
    color: #fff !important;
  }
  button {
    background-color: $clBlue;
  }

}

.navbar-scroll {
  .nav-link,
  .fa-bars {
    color: #fff;
  }
}

.navbar-scrolled {
  .nav-link,
  .fa-bars {
    color: #4f4f4f;
  }
}

.navbar-scrolled {
  background-color: #fff;
}
