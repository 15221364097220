.datatable, .dx-g-bs4-table {
  --datatable-color: rgb(33, 37, 41);
  --datatable-border-color: rgb(224, 224, 224);
  --datatable-striped-color: rgb(33, 37, 41);
  --datatable-accent-bg: rgb(242, 242, 242);
  --datatable-hover-color: rgb(19, 19, 19);
  --datatable-hover-bg: rgb(242, 242, 242);
  --datatable-muted-color: grey;
  --datatable-active-color: rgba(19, 19, 19, 0.05);

  font-size: $datatable-font-size;
  color: var(--datatable-color);
  background-color: rgba(255, 255, 255, 0);
  background: none !important;
  font-family: $font-family-base;
  font-weight: 500;
  width: 100%;

  &.team {
    color: $clOrange !important;

    tbody tr {
      color: $clOrange !important;
    }
    td {
      &:first-of-type {
        border-left: 10px $clOrange solid;
      }
    }
    button {
      color: $clOrange !important;
      border-color: $clOrange !important;
    }
  }

  &.survey {
    color: $clRed !important;

    tbody tr {
      color: $clRed !important;
    }
    td {
      &:first-of-type {
        border-left: 10px $clRed solid;
      }
    }
    button {
      color: $clRed !important;
      border-color: $clRed !important;
    }
  }

  &.dashboard {
    color: $clBlue !important;

    tbody tr {
      color: $clBlue !important;
    }
    td {
      &:first-of-type {
        border-left: 10px $clBlue solid;
      }
    }
    button {
      color: $clBlue !important;
      border-color: $clBlue !important;
    }
  }

  &.subscribers {
    td {
      vertical-align: middle;
    }
  }

  &-inner {
    background-color: inherit;
    background: none !important;
  }

  table {
    background-color: inherit;
    margin-bottom: 0;
    border-color: var(--datatable-border-color);
    background: none !important;
    border-collapse: separate;
    border-spacing: 0 10px;
    margin-top: -10px;

    th,
    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: $datatable-table-th-td-max-width;
      padding: $datatable-table-th-td-padding-y $datatable-table-th-td-padding-x;
    }
  }
  thead {
    color: var(--datatable-color);
    background-color: #eef1f7 !important;
    //background: none !important;
    tr {
      background-color: inherit;
      border-bottom: $datatable-thead-tr-border-width solid var(--datatable-border-color);
      background: none !important;
    }
    th {
      position: relative;
      border-bottom: none;
      font-weight: $datatable-thead-th-font-weight;

      &:hover {
        .datatable-sort-icon {
          opacity: 1;
        }
      }
    }

    .fixed-cell {
      position: sticky;
      top: 0;
      z-index: 3;
      box-shadow: var(--datatable-border-color) 0px 1px;
      //background-color: $datatable-thead-fixed-cell-background-color;
      background-color: #eef1f7 !important;
      //background: none !important;
    }
  }

  tbody {
    color: $clGrey;
    font-weight: $datatable-tbody-font-weight !important;
    background-color: inherit;
    background: none !important;
    .fixed-cell {
      position: sticky;
      z-index: 1;
      background-color: inherit;
      //background: none !important;
    }

    tr {
      border-radius: 10px;
      transition: $datatable-tbody-tr-transition;
      border-bottom: 20px $pageBackground solid;

      &:last-child {
        border-bottom: 0px $pageBackground solid;
      }

      &:focus {
        outline: none;
      }

      &.active {
        background-color: var(--datatable-active-color);
        background: none !important;
      }

      &.datatable-results-info {
        td {
          background: none !important;
          color: $black !important;
          border-bottom: none;
        }
      }
    }

    td {
      background-color: $white !important;

      &:focus {
        outline: none;
      }

      &:first-of-type {
        padding-left: 20px;
        //border-top-left-radius: 10px;
        //border-bottom-left-radius: 10px;
        border-left-style: solid;
      }
      &:last-of-type {
        border-right-style: solid;
        padding-right: 20px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

    }
  }

  &-loader {
    width: 100%;
    height: $datatable-tbody-loader-height;
    position: relative;
    overflow: hidden;
  }

  &-loader-inner {
    display: block;
    height: 100%;
  }

  &-progress {
    animation: $datatable-tbody-progress-animation;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    width: $datatable-tbody-progress-width;
    position: relative;
    opacity: $datatable-tbody-progress-opacity;
    border-radius: $datatable-tbody-progress-border-radius;
    display: block;
    height: 100%;
  }

  @keyframes datatableProgress {
    0% {
      left: -45%;
    }
    100% {
      left: 100%;
    }
  }

  &-pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: $datatable-pagination-padding-y 0;
    border-top: $datatable-pagination-border-width solid var(--datatable-border-color);
  }

  &-pagination-nav {
    padding: 0;
    font-size: $datatable-pagination-nav-font-size;
    direction: ltr #{'/*!rtl:ignore*/'};
  }

  &-pagination-buttons {
    margin-left: $datatable-pagination-buttons-margin-left;
  }

  &-pagination-button {
    padding-left: $datatable-pagination-button-padding-x;
    padding-right: $datatable-pagination-button-padding-x;
    color: var(--datatable-color);
  }

  &-sort-icon {
    opacity: 0;
    color: var(--datatable-muted-color);
    cursor: pointer;
    transition-property: opacity, transform;
    transition-duration: $datatable-sort-icon-transition-duration;
    transition-timing-function: linear;
    position: absolute;
    left: $datatable-sort-icon-left;
    top: $datatable-sort-icon-top;

    &.active {
      opacity: 1;
      color: var(--datatable-color);
    }
  }

  &-select-wrapper {
    display: flex;
    align-items: center;
    font-size: $datatable-select-wrapper-font-size;
    font-weight: $datatable-select-wrapper-font-weight;
    padding: 0 1rem;

    .select-input {
      max-width: 70px;
    }
  }

  &-select-text {
    margin: 0 1rem;
  }

  &.datatable-sm {
    th,
    td {
      padding: $datatable-sm-th-td-padding-y $datatable-sm-th-td-padding-x;
    }

    tbody {
      tr:last-child {
        border-bottom: transparent;
        height: $datatable-sm-tbody-tr-last-child-height;
      }
    }

    .datatable-pagination {
      padding: $datatable-sm-pagination-padding 0;
    }
  }

  &.datatable-bordered {
    th,
    td {
      border-width: $datatable-bordered-th-td-border-width;
    }

    .datatable-pagination {
      border: $datatable-bordered-th-td-border-width solid var(--datatable-border-color);
    }
  }

  &.datatable-striped {
    tbody {
      tr:nth-of-type(odd) {
        background-color: var(--datatable-accent-bg);
        background: none !important;
        color: var(--datatable-striped-color);
      }
    }
  }

  &.datatable-hover {
    tbody {
      tr {
        transition: $datatable-hover-tbody-tr-transition !important;

        &:hover {
          background-color: var(--datatable-hover-bg) !important;
          background: none !important;
        }
      }
    }
  }

  &.datatable-clickable-rows {
    tbody {
      tr {
        cursor: pointer;

        &.active {
          background-color: var(--datatable-hover-bg);
          background: none !important;
        }
      }
    }
  }

  &.datatable-dark {
    --datatable-color: #{#fff};
    --datatable-border-color: #{#fff};
    --datatable-active-color: #{rgba(255, 255, 255, 0.2)};

    .select-wrapper {
      .select-arrow,
      input {
        color: white;
      }

      .form-outline .form-control:focus ~ .form-notch .form-notch-leading,
      .form-notch-trailing {
        border-color: rgb(251, 251, 251) !important;
      }
      .form-notch-leading,
      .form-noth-middle,
      .form-notch-trailing {
        border-color: rgb(251, 251, 251) !important;
      }

      .form-outline .form-control:focus ~ .form-notch .form-notch-leading {
        border-color: rgb(251, 251, 251) !important;
      }
    }

    .form-check-input[type='checkbox']:checked {
      background-color: transparent;
      background: none !important;
      border-color: $datatable-dark-check-border-color;
    }

    .datatable-progress {
      opacity: $datatable-dark-datatable-progress-opacity;
    }

    &.datatable-striped {
      --datatable-striped-color: #{white};
      --datatable-accent-bg: #{rgba(255, 255, 255, 0.05)};
    }

    &.datatable-hover {
      --datatable-hover-bg: #{rgba(255, 255, 255, 0.2)};
      --datatable-hover-color: #{white};
    }
  }

  @each $color, $value in $theme-colors {
    &.border-#{$color} {
      --datatable-border-color: #{$value};
    }
  }

  &.datatable-borderless {
    --datatable-border-color: #{transparent};
  }

  &.datatable-loading {
    color: var(--datatable-muted-color);

    th {
      color: var(--datatable-muted-color);
    }

    .datatable-sort-icon {
      display: none;
    }
  }
}


//.survey {
//  color: $clRed !important;
//
//  tbody tr {
//    color: $clRed !important;
//  }
//  td {
//    &:first-of-type {
//      border-left: 10px $clRed solid;
//    }
//  }
//  button {
//    color: $clRed !important;
//    border-color: $clRed !important;
//  }
//}

.company {

  color: $clGreen !important;

  tbody tr {

    cursor: pointer;

    p, a {
      color: $clGrey !important;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    td {
      &:first-of-type {
        border-left: 10px $clGrey solid;
      }
    }

    &.inactive {
      p, a {
        color: transparent !important;
        text-shadow: 0 0 5px rgba(0,0,0,0.5);
      }
    }

    &:not(.inactive) {
      cursor: pointer;
      color: $clGreen !important;

      td {
        &:first-of-type {
          border-left: 10px $clGreen solid;
        }
      }

      p, a:not(.dropdown-item) {
        color: $clGreen !important;
      }
    }
  }
}

.dashboard {

  color: $clBlue !important;

  tbody tr {

    cursor: default;

    p, a {
      color: $clGrey !important;
    }

    td {
      &:first-of-type {
        border-left: 10px $clGrey solid;
      }
    }

    &.inactive {
      cursor: default;

      p, a {
        color: transparent !important;
        text-shadow: 0 0 5px rgba(0,0,0,0.5);
      }

      .progress-bar-rail {
        color: transparent !important;
        text-shadow: 0 0 5px rgba(0,0,0,0.5);
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
      }
    }

    &:not(.inactive) {
      color: $clBlue !important;

      td {
        &:first-of-type {
          border-left: 10px $clBlue solid;
        }
      }

      p, a:not(.dropdown-item) {
        color: $clBlue !important;
      }
    }
  }
}

.team {

  color: $clOrange !important;

  tbody tr {

    cursor: default;

    p, a {
      color: $clGrey !important;
    }

    td {
      &:first-of-type {
        border-left: 10px $clGrey solid;
      }
    }

    &.inactive {
      cursor: default !important;

      p, a {
        color: transparent !important;
        text-shadow: 0 0 5px rgba(0,0,0,0.5);
      }
    }

    &:not(.inactive) {
      color: $clOrange !important;

      td {
        &:first-of-type {
          border-left: 10px $clOrange solid;
        }
      }

      p, a:not(.dropdown-item) {
        color: $clOrange !important;
      }
    }
  }
}

.survey {

  color: $clRed !important;

  tbody tr {

    cursor: default;

    p, a {
      color: $clGrey !important;
    }

    td {
      &:first-of-type {
        border-left: 10px $clGrey solid;
      }
    }

    &.inactive {
      cursor: default !important;

      p, a {
        color: transparent !important;
        text-shadow: 0 0 5px rgba(0,0,0,0.5);
      }
    }

    &:not(.inactive) {
      color: $clRed !important;

      td {
        &:first-of-type {
          border-left: 10px $clRed solid;
        }
      }

      p, a:not(.dropdown-item) {
        color: $clRed !important;
      }
    }
  }
}

.dx-g-bs4-table {
  //border-spacing: 0 10px !important;

  tbody {
    table-layout: fixed;
    border-collapse: separate;
    //border-spacing: 0 10px !important;
    margin: 0;

  }

  .dropdown-toggle {
    font-weight: 500;

    &.btn-light {
      color: $clGrey !important;
    }
    &.btn-success {
      color: $white !important;
    }
  }

}

.overflow {
  overflow: visible;
}


@media (max-width: 520px) {
  .datatable-pagination {
    flex-direction: column-reverse;

    &-nav {
      margin: 0.8rem 0;
    }

    &-button {
      font-size: 1rem;
    }

    &-buttons {
      margin: 0.8rem 0;
    }
  }
}
