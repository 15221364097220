@import url("https://use.typekit.net/ooy8mrl.css");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/myriad-pro');
@import "./variables";
@import './sidenav';
@import './theme/components/sidenav-theme';
@import './navbar';
@import './report';
@import './stepper';
@import "datatable";

  body {
    background-color: $pageBackground !important;
    font-family: $font-family-base;
    &.modal-open {
      overflow: visible !important;
    }

  }

  h1, h2, h3, h4, h5, h6 {
    font-family: $font-family-base !important;
    font-weight: 300;
    font-style: normal;
    &.team {
      color: $clOrange !important;
    }
    &.company {
      color: $clGreen !important;
    }

  }

  p {
    font-family: $font-family-base;
    font-weight: 400;
    font-style: normal;
    color: $clGrey;
    &.team {
      color: $clOrange !important;
    }
    &.company {
      color: $clGreen !important;
    }
    &.explainer {
      color: $clGrey !important;
      font-family: $font-family-base !important;
      font-size: 0.7rem;
      line-height: 1rem;
      letter-spacing: normal;
      padding-top: 0 !important;
      padding-bottom: 15px !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  a {
    cursor: pointer;
  }

  #intro {
    background-image: url('../../assets/img/welcome-page-background.jpg');
    height: 100vh;
  }

  .container {
    max-width: none;
  }

  .page-header {
    background-color: $pageBackground;
    //margin-bottom: 40px;
    padding: 2.5rem 3rem 0 3rem;
    //box-shadow: 0 10px 20px 0 $shadowColor;
  }
  .page-description {
    background-color: $pageBackground;
    //margin-bottom: 40px;
    padding: 2.5rem 3rem;
    //box-shadow: 0 10px 20px 0 $shadowColor;
  }

  .add-button {
    margin: 20px 0;
  }

  .user-dropdown {
    padding-right: 20px;
    cursor: pointer;
  }

  .dropdown-menu {
    li {
      cursor: pointer;
    }
  }

  .dropdown-toggle {
    font-family: $font-family-base;
    font-weight: 400;
    font-style: normal;
    color: $shadowColor;
    i {
      color: $white;
    }
  }

  .send-button {
    background-color: $p2 !important;
    font-family: $font-family-base;
    font-style: normal;
    color: $white;
    font-weight: 400;
    i {
      color: $white;
    }
  }

.roi-button {
  background-color: $p1 !important;
  margin: 2.5rem 0 0 3rem;
  font-family: $font-family-base;
  font-style: normal;
  color: $white;
  font-weight: 400;
  i {
    color: $white;
  }
}

.login-register-header {
    text-align: center;
    margin: 50px 20px 0 20px;
  }

  .login-register-footer {
    text-align: center;
    margin: 30px 0;
  }

  .bg-white {
    background-color: #FFFFFF !important;
  }

  .questionnaire-header {
    background-color: $white;
    text-align: center;
    margin: 50px 20px 0 20px;
  }
  .questionnaire-intro {
    background-color: $white;
    text-align: center;
    width: 75%;

    font-family: $font-family-base !important;
    font-weight: 400;
    font-style: normal;

  }

  .feedback-question-row {
    margin-bottom: 3rem;
  }

  .error {
    font-family: $font-family-base;
    color: $clRed;
    display: block;
    width: 100%;
    margin: 1rem 0 1rem 0;
  }

  .input-error {
    font-family: $font-family-base;
    color: $clRed;
    width: 100%;
    //margin: 1rem 0 1rem 0;
  }

  .form-check-label {
    font-family: $font-family-base;
    font-weight: 400;
    font-style: normal;
    color: $clGrey;
    width: 95%;
  }

  .formButton {
    width: 100%;
  }

//
// General form controls (plus a few specific high-level interventions)
//

.form-control {
  display: block;
  width: 100%;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  appearance: none; // Fix appearance for date inputs in Safari

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include border-radius($input-border-radius, 0);

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  &[type='file'] {
    overflow: hidden; // prevent pseudo element button overlap

    &:not(:disabled):not([readonly]) {
      cursor: pointer;
    }
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  &:focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $input-focus-box-shadow;
    }
  }

  // Add some height to date inputs on iOS
  // https://github.com/twbs/bootstrap/issues/23307
  // TODO: we can remove this workaround once https://bugs.webkit.org/show_bug.cgi?id=198959 is resolved
  &::-webkit-date-and-time-value {
    // Multiply line-height by 1em if it has no unit
    height: if(unit($input-line-height) == '', $input-line-height * 1em, $input-line-height);
  }

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled {
    color: $input-disabled-color;
    background-color: $input-disabled-bg;
    border-color: $input-disabled-border-color;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }

  // File input buttons theming
  &::file-selector-button {
    padding: $input-padding-y $input-padding-x;
    margin: (-$input-padding-y) (-$input-padding-x);
    margin-inline-end: $input-padding-x;
    color: $form-file-button-color;
    @include gradient-bg($form-file-button-bg);
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: $input-border-width;
    border-radius: 0; // stylelint-disable-line property-disallowed-list
    @include transition($btn-transition);
  }

  &:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: $form-file-button-hover-bg;
  }
}

// Readonly controls as plain text
//
// Apply class to a readonly input to make it appear like regular plain
// text (without any border, background color, focus indicator)

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: $input-padding-y 0;
  margin-bottom: 0; // match inputs if this class comes on inputs with default margins
  line-height: $input-line-height;
  color: $input-plaintext-color;
  background-color: transparent;
  border: solid transparent;
  border-width: $input-border-width 0;

  &:focus {
    outline: 0;
  }

  &.form-control-sm,
  &.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }
}

// Form control sizing
//
// Build on `.form-control` with modifier classes to decrease or increase the
// height and font-size of form controls.
//
// Repeated in `_input_group.scss` to avoid Sass extend issues.

.form-control-sm {
  min-height: $input-height-sm;
  padding: $input-padding-y-sm $input-padding-x-sm;
  @include font-size($input-font-size-sm);
  @include border-radius($input-border-radius-sm);

  &::file-selector-button {
    padding: $input-padding-y-sm $input-padding-x-sm;
    margin: (-$input-padding-y-sm) (-$input-padding-x-sm);
    margin-inline-end: $input-padding-x-sm;
  }
}

.form-control-lg {
  min-height: $input-height-lg;
  padding: $input-padding-y-lg $input-padding-x-lg;
  @include font-size($input-font-size-lg);
  @include border-radius($input-border-radius-lg);

  &::file-selector-button {
    padding: $input-padding-y-lg $input-padding-x-lg;
    margin: (-$input-padding-y-lg) (-$input-padding-x-lg);
    margin-inline-end: $input-padding-x-lg;
  }
}

// Make sure textareas don't shrink too much when resized
// https://github.com/twbs/bootstrap/pull/29124
// stylelint-disable selector-no-qualifying-type
textarea {
  &.form-control {
    min-height: $input-height;
  }

  &.form-control-sm {
    min-height: $input-height-sm;
  }

  &.form-control-lg {
    min-height: $input-height-lg;
  }
}
// stylelint-enable selector-no-qualifying-type

.form-control-color {
  width: $form-color-width;
  height: $input-height;
  padding: $input-padding-y;

  &:not(:disabled):not([readonly]) {
    cursor: pointer;
  }

  &::-moz-color-swatch {
    border: 0 !important; // stylelint-disable-line declaration-no-important
    @include border-radius($input-border-radius);
  }

  &::-webkit-color-swatch {
    @include border-radius($input-border-radius);
  }

  &.form-control-sm {
    height: $input-height-sm;
  }
  &.form-control-lg {
    height: $input-height-lg;
  }
}

.form-control {
    font-family: $font-family-base !important;
    background: $white !important;
    color: $clGrey !important;
  }

  .login-register-form {
    overflow: hidden;
  }

  .questionnaire-form {
    overflow: hidden;
    max-height: 75vh !important;
  }


.modal-divider {
    width: 100%;
    height: 44px;
    background-color: $white;
    box-sizing: content-box;
    border-bottom: 12px $clPurple solid;
    border-right: 12px $clPurple solid;
    border-bottom-right-radius: 44px;
    //margin: 50px 0;
  }

  .row-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  //.questionnaire-row {
  //  height: 4em;
  //}

  .question {
    flex: 0 0 auto;
  }

  .answer {
    display: flex;
  }

  .answer h2 {
    margin-top: -6px;
    margin-left: 20px;
  }

  .stepper-head, .stepper-mobile-head {
    font-family: $font-family-base;
  }

  .stepper-head-icon {
    flex: none;
  }

  .score-selector-label {
    color: $clBlue;
    font-family: "Roboto", sans-serif;
    font-size: 0.8em;
    font-weight: normal;
    line-height: 0.8em;
    margin-bottom: 0;
  }

  .score-selector {
    padding-right: 30px;
  }

  .dashboard-count {
    //height: 100%;
    //padding: 20px 20px 10px;
    border-bottom: 1px $black solid;
    padding: 15px 0;

    &:first-of-type {
      border-bottom: none;
    }

    p {
      margin-bottom: 0;
    }
    a, i {
      flex-direction: column;

      &.team {
        color: $clOrange !important;
      }
      &.company {
        color: $clGreen !important;
      }
    }

  }

  .dashboard-companies-header {
    border-bottom: 1px $lightGrey solid;
  }

  .dashboard-companies-row {
    border-bottom: 1px $lightGrey solid;

    &:last-of-type {
      border-bottom: none;
    }
    .counter {
      font-size: 1rem;
      font-weight: 700;
      background-color: $clBlue;
      border-radius: 20px;
      padding: 3px 13px;

      p {
        margin: 0;
        color: $white;
      }
    }
  }

  .company-card-logo {
    width: auto;
    max-height: 180px;
  }


.dashboard-pie {

  background:none !important;

}

.accordion-body {
  padding: 0 !important;
  margin: 0 !important;
}

.accordion-item {
  background:none !important;
}

.accordion-header {
  font-size: 1rem;
  display: flex;
  justify-content: space-between;

  .accordion-button {
    font-size: medium;
    justify-content: space-between;
    padding: 20px 0;
    background:none !important;

    &:after {
      flex-shrink: 0;
      width: 1rem;
      height: 1rem;
      margin-left: auto;
      content: '';
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234f4f4f'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: 1rem;
      transition: transform 0.2s ease-in-out;
    }
  }
}

  .placeholder {
    display: flex;
    border-radius: 5px;
    //justify-content: center;
    align-items: center;
    flex-direction: column;

    &.company {
      a {
        flex-direction: column;
        color: $clGreen !important;
      }
    }
    &.team {
      a {
        flex-direction: column;
        color: $clOrange !important;
      }
    }
  }

  .dashboard-placeholder {
    h2, h4 {
      color: $clGrey !important;
    }

    a {
      color: $black !important;
    }
  }

  .card {
    //box-shadow: none !important;
    //min-height: 320px;
    //max-height: 400px;
    //min-width: 200px !important;

    &.small-card {
      min-height: 240px;
      max-height: 240px;
      min-width: 0px !important;
    }

    &.team-card {
      max-height: none;
    }

    .image-tag {
      padding: 20px;
      box-shadow: 0 2px 5px 0 $shadowColor;
      text-align: center;
      min-height: 220px;

    }
  }

.team-badge {
  width: 80px;
  height: 80px;
  position: absolute;
  right: 20px;
  top: 180px;
  z-index: 1;
  border-width: 2px;
  border-color: $clOrange;
  border-radius: 50%;
  box-shadow: 0 2px 5px 0 $shadowColor;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  a, i {
    flex-direction: column;

    &.team {
      color: $clOrange !important;
    }
    &.company {
      color: $clGreen !important;
    }
  }

}

  .card-body {
    display: flex;
    //border: 3px $lightGrey dashed;
    //justify-content: center;
    align-items: center;
    flex-direction: column;

    &.participant-status {
      min-height: 150px;
    }

    .card-title {
      &.team {
        color: $clOrange !important;
      }
      &.company {
        color: $clGreen !important;
      }

    }

    a, i {
      flex-direction: column;

      &.team {
        color: $clOrange !important;
      }
      &.company {
        color: $clGreen !important;
      }
    }

    button, .btn, .btn-primary {
      text-transform: none !important;

      &.team {
        color: $clOrange !important;
        border-color: $clOrange !important;
      }
      &.company {
        color: $clGreen !important;
        border-color: $clGreen !important;
      }
      &.survey {
        background-color: $clRed !important;
        border-color: $clRed !important;
      }
    }
  }

  .video-card {
    .card-body {
      min-height: 150px;
      .btn {
        color: $white !important;

        &.team {
          background-color: $clOrange !important;
        }
        &.company {
          background-color: $clGreen !important;
        }
        &.survey {
          background-color: $clRed !important;
        }
      }
    }

  }

  .btn-floating {
    box-shadow: none;
    i {
      &.team {
        background-color: $clOrange !important;
      }
      &.company {
        background-color: $clGreen !important;
      }
      &.survey {
        background-color: $clRed !important;
      }
    }

    &:not(.btn-outline-primary) {
      i {
        color: $white !important;
      }
    }

  }

  .update-btn {
    text-transform: none;
  }

  .active-company {
    color: $clGreen;
  }

  .inactive-company {
    color: $clRed;
  }

  .card-header {
    font-family: $font-family-base;
    font-weight: 400;
    font-style: normal;
    color: $clGrey;
  }

  .collapse {
    height: auto !important;
  }

  .accordion-button {
    font-family: $font-family-base;
    font-size: large;
    font-weight: 400;
    font-style: normal;
    color: $clGrey;
  }

  .btn-floating {
    background-color: $white;
  }


  .dropdown-toggle {
    color: $white !important;
  }

  .modal-dialog {
    max-width: 70%;
    font-family: $font-family-base !important;
  }

  .progress-bar-rail {
    border-radius: 10px;
  }

  .progress-bar {
    background-color: $clGreen !important;
    font-family: $font-family-base;
    font-size: medium;
    font-weight: 400;
    font-style: normal;
    color: $white;
  }

  .dashboard-company-hero {
    min-height: 230px;
    background-color: $white;
  }

  .company-image {
    border: none !important;
    max-height: 200px;
  }

  .company-image-placeholder {
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 180px;
    width: 100%;

    h1 {
      font-family: $font-family-base;
      font-size: 2rem;
      font-weight: 400;
      color: $clGrey;
    }

  }

  .privacy {
    font-family: $font-family-base;
    font-weight: bold;
    font-size: 0.8rem;
    width: 95%;
  }

  p.privacy {
    margin-left: 1.6rem;
  }

  .form-outline {
    &.datepicker {
      background-color: #FFF;
    }
  }

  .hidden {
    display: none;
  }

  .scrollspy-faq {
    position: relative;
    height: 300px;
    overflow: auto;

    section {
      padding-bottom: 70px;

      h3 {
        margin-bottom: 20px;
      }
      h5 {
        font-family: $font-family-base;
        margin-top: 10px;
        margin-bottom: 10px;

        &.p1 {
          color: $p1;
        }
        &.p2 {
          color: $p2;
        }
        &.p3 {
          color: $p3;
        }
        &.p4 {
          color: $p4;
        }
        &.p5 {
          color: $p5;
        }
        &.p6 {
          color: $p6;
        }
      }
      ul {
        list-style: none;
        margin: 40px 0;
      }
    }
  }
  .faq-header {
    background-color: $white;
    text-align: center;
    margin: 50px 20px 0 20px;
  }
  .faq-row {
    background-color: $white;
    width: 75%;
  }

  .sidenav-footer {
    background-color: $white;
  }

  .dropdown-item-text {
    padding-left: 0;

    span {
      text-transform: uppercase;
      color: $p1 !important;
      font-weight: bold !important;
    }
  }

.dx-g-bs4-table {

}

.toast-info {
  color: #084154 !important;
}

.toast-header .toast-info {
  background-color: transparent;
}

.modal-row {
  border-bottom: 1px #e0e0e0 solid;
  padding: 20px 0;
}

.modal-body {

  p, span {
    font-family: $font-family-base !important;
  }
}

.floating-info {
  position: absolute;
  text-align: right;
  z-index: 2;
  top: 20px;
  right: 20px;
}

/* Height for devices larger than 576px */
  //@media (min-width: 992px) {
  //  #intro {
  //    margin-top: -58.59px;
  //  }
  //}


.summary-input-container .form-control {
  padding: 20px !important;
}

.summary-input-container {
  background: transparent;
  color: #6F7271;
  position: relative;
  font-size: 14px;
}

.summary-input-content {
  min-height: 100px;
  padding: 20px;
}

[contenteditable]:focus {
  outline: 0px solid transparent;
}

.summary-input-container .label {
  color: #6F7271;
  position: absolute;
  top: -10px;
  left: 10px;
  padding: 0 5px;
}

.summary-input-container .form-notch {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  text-align: left;
  pointer-events: none;
}

.summary-input-container .form-notch-leading {
  left: 0;
  top: 0;
  height: 100%;
  width: 0.5rem;
  border-right: none;
  border-radius: 0.25rem 0 0 0.25rem;
  border-left: solid 1px #6F7271;
  border-top: solid 1px #6F7271;
  border-bottom: solid 1px #6F7271;
}

.summary-input-container .form-notch-middle {
  flex: 0 0 auto;
  width: 155px;
  max-width: calc(100% - 1rem);
  height: 100%;
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: solid 1px #6F7271;
}

.summary-input-container .form-notch-trailing {
  flex-grow: 1;
  height: 100%;
  border-left: none;
  border-radius: 0 0.25rem 0.25rem 0;
  border-top: solid 1px #6F7271;
  border-right: solid 1px #6F7271;
  border-bottom: solid 1px #6F7271;
}

.report {
  width: 90%;
  max-width: 1200px;
  margin: 100px auto;
}

.summary-input-container.print {
  font-size: 12px;
  margin-top: 0 !important;
}

.roi-theme-chart {
  height: 460px;
  margin-bottom: 30px;
  padding: 0 20px;
}

.roi-report-generate-button-container {
  position: absolute;
  top: 320px;
  right: 0;
}

.datatable.subscribers .table thead tr th:nth-child(3),
.datatable.subscribers .table tbody tr td:nth-child(3) {
  display: none;
}

.inner-subscriptions {
  padding: 5px;
}

.avatar {
  border-radius: 50%;
  display: flex;
  width: 45px;
  height: 45px;
  border-radius: 23px;
  background-color: #00acc1;
  flex: 1;
  align-items: center;
  justify-content: center;

  h2 {
    font-weight: 600;
    color: $white;
    margin: 0;
    //line-height: 1.5rem;
    //font-size: 1.5rem;
  }

  &.header {
    display: inline;
  }
}

.sub-badge {

  //padding: 6px 10px;
  //border-radius: 20px;


  &.bronze {
    background-color: $bronzeBg;
    color: $bronze;
  }
  &.silver {
    background-color: $silverBg;
    color: $silver;
  }
  &.gold {
    background-color: $goldBg;
    color: $gold;
  }
}

.sub-certificate {

  //padding: 6px 10px;
  //border-radius: 20px;


  &.bronze {
    color: $bronzeBg;
  }
  &.silver {
    color: $silverBg;
  }
  &.gold {
    color: $goldBg;
  }
}

.tier-card {

  cursor: pointer;
  &.selected {
    border: 3px $clGreen solid;
  }
}