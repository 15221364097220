body {
  background: none !important;
  background-color: transparent !important;
  color: $reportText;
}

.report-generating {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  .animation {
    height: 150px;
  }

  h1 {
    color: $p1;
  }
}

span, p, h1, h2, h3, h4, .main-text, i {
  color: $reportText;

  &.p1 {
    color: $p1;
  }
  &.p2 {
    color: $p2;
  }
  &.p3 {
    color: $p3;
  }
  &.p4 {
    color: $p4;
  }
  &.p5 {
    color: $p5;
  }
  &.p6 {
    color: $p6;
  }
}


blockquote{
  font-size: 1.4em;
  width:80%;
  margin:50px auto;
  font-family: $font-family-hypatia;
  font-style:italic;
  color: $clGrey;
  padding:1.2em 30px 1.2em 75px;
  border-left:8px solid $clGreen;
  line-height:1.6;
  position: relative;
  background:#EDEDED;
}

blockquote::before{
  font-family:Arial;
  content: "\201C";
  color:$clGreen;
  font-size:4em;
  position: absolute;
  left: 10px;
  top:-10px;
}

blockquote::after{
  content: '';
}

blockquote span{
  display:block;
  color:$clGrey;
  font-style: normal;
  font-weight: bold;
  margin-top:1em;
}

.report {
  max-width: 1200px;

  h5 {
    font-weight: 600 !important;
  }

}

.myriad {
  font-family: 'Myriad Pro', sans-serif;
}

.section-header {
  display: flex;
  width: 100%;
  height: 112px;
  color: $white !important;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 3rem;

  &.p1 {
    background-image: url("../../assets/img/report/section-header-p1.png");
    background-size: cover;
  }
  &.p2 {
    background-image: url("../../assets/img/report/section-header-p2.png");
  }
  &.p3 {
    background-image: url("../../assets/img/report/section-header-p3.png");
  }
  &.p4 {
    background-image: url("../../assets/img/report/section-header-p4.png");
  }
  &.p5 {
    background-image: url("../../assets/img/report/section-header-p5.png");
  }
  &.p6 {
    background-image: url("../../assets/img/report/section-header-p6.png");
  }

  h1 {
    font-family: $font-family-hypatia !important;
    font-size: 1em;
    line-height: 2.6rem;
    color: $white !important;
  }
  h2 {
    font-family: $font-family-hypatia !important;
    font-size: 1em;
    color: $white !important;
  }

  //@media screen and (max-width: 1290px) {
  //  height: 120px;
  //
  //  //h1 {
  //  //  font-size: 1.1em;
  //  //  line-height: 2.5rem;
  //  //}
  //  //h2 {
  //  //  font-size: 1em;
  //  //}
  //}
  //@media screen and (max-width: 1170px) {
  //  height: 112px;
  //
  //  //h1 {
  //  //  font-size: 1em;
  //  //  line-height: 2.2rem;
  //  //}
  //  //h2 {
  //  //  font-size: 1em;
  //  //}
  //}

}

.section-header-lite {
  display: flex;
  width: 100%;
  height: 167px;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 3rem;

  &.p1 {
    background-image: url("../../assets/img/TeamLeaderOverviewReport/section-header-p1.png");
  }
  &.p2 {
    background-image: url("../../assets/img/TeamLeaderOverviewReport/section-header-p2.png");
  }
  &.p3 {
    background-image: url("../../assets/img/TeamLeaderOverviewReport/section-header-p3.png");
  }
  &.p4 {
    background-image: url("../../assets/img/TeamLeaderOverviewReport/section-header-p4.png");
  }
  &.p5 {
    background-image: url("../../assets/img/TeamLeaderOverviewReport/section-header-p5.png");
  }
  &.p6 {
    background-image: url("../../assets/img/TeamLeaderOverviewReport/section-header-p6.png");
  }


  h1 {
    font-family: $font-family-hypatia !important;
    font-size: 1.3em;
    color: $white !important;

  }
  h2 {
    font-family: $font-family-hypatia !important;
    font-size: 1em;
    color: $white !important;

  }
}

.subsection-header{
  font-family: $font-family-hypatia;
  font-size: 1.5em;
  letter-spacing: -0.1rem;
}

.red {
  color: $clRed;
}
.orange {
  color: $clOrange;
}
.blue {
  color: $clBlue;
}


.report-hero {
  width:100%;
}

.report-page {
  font-family: $font-family-base;
  margin-left:6rem;
  margin-right:6rem;
}

.report-page-landscape {
  font-family: $font-family-base;
  margin-left:4rem !important;
  margin-right:4rem !important;
  display: flex;
  flex-direction: column;
  flex-basis:100%;
}

.summary {
  flex: 1;
  flex-direction: column;
  width: 80%;
  font-family: $font-family-base;

  .summary-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .summary-col {
    flex: 0.3;

    &:last-of-type {
      flex: 0.7;
    }
  }
}

.team-table-legend {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2em;

  .legend-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .colourbox {
      min-width: 40px;
      max-width: 40px;
      min-height: 80px;
      max-height: 80px;
      margin-right: 15px;
      margin-left: 15px;
      &.good {
        background-color: $good;
      }
      &.bad {
        background-color: $bad;
      }
      &.average {
        background-color: $average;
      }
    }

    .caption {
      color: $reportText !important;
      font-family: $font-family-base !important;
      font-size: 0.8rem;
      letter-spacing: normal;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    &:first-of-type {
      .colourbox {
        margin-left: 0px;
      }
    }

  }
}

.team-table {
  border-collapse: separate;
  border-spacing: 0px;
  border: 4px $clOrange solid !important;
  border-top-right-radius: 14px !important;
  border-bottom-left-radius: 30px !important;

  &.p1 {
    border: 4px $p1 solid !important;
  }
  &.p2 {
    border: 4px $p2 solid !important;
  }
  &.p3 {
    border: 4px $p3 solid !important;
  }
  &.p4 {
    border: 4px $p4 solid !important;
  }
  &.p5 {
    border: 4px $p5 solid !important;
  }
  &.p6 {
    border: 4px $p6 solid !important;
  }

  th {
    vertical-align: middle;
    color: $white;

    &.question-header {
      font-size: 2rem;
      p, span {
        color: $white !important;
      }
    }
    &.question {
      color: $reportText;
    }
    &.p1 {
      background-color: $p1;
    }
    &.p2 {
      background-color: $p2;
    }
    &.p3 {
      background-color: $p3;
    }
    &.p4 {
      background-color: $p4;
    }
    &.p5 {
      background-color: $p5;
    }
    &.p6 {
      background-color: $p6;
    }

    &.team-table-header > span {
      writing-mode: vertical-rl;
      -ms-writing-mode: vertical-rl;
      -webkit-writing-mode: vertical-rl;
      -moz-writing-mode: vertical-rl;
      inline-size: 190px;
      color: $white !important;

      &.white {
        color: #FFFFFF;
      }
    }
  }

  td {
    font-weight: 600;
    color: $reportText;
    &.highest {
      background-color: $good;
    }
    &.lowest {
      background-color: $bad;
    }
    &.average {
      background-color: $average;
    }
  }

  tbody {
    tr:nth-of-type(2n+1) {

      &.p1 {
          background-color: $p1Pastel;
      }
      &.p2 {
        background-color: $p2Pastel;
      }
      &.p3 {
        background-color: $p3Pastel;
      }
      &.p4 {
        background-color: $p4Pastel;
      }
      &.p5 {
        background-color: $p5Pastel;
      }
      &.p6 {
        background-color: $p6Pastel;
      }
    }
  }

}

.rating-table {

  .rating-col {

    text-align: right;
    border-right: 2px dashed $clGrey;

    &:last-of-type {
      border-right: none;
    }

    .col-heading {
      font-family: $font-family-hypatia;
      font-size: 2rem;
      letter-spacing: -0.1rem;
      border-bottom: 2px dashed $clGrey;
      padding-bottom: 10px;
      padding-right: 20px;

      &.highest {
        color: $p4;
      }

      &.lowest {
        color: $p2;
      }

      &.average {
        color: $clOrange;
        line-height: 2rem;
        padding-right: 0px;
        padding-left: 20px;
        padding-bottom: 0px;
      }
    }

    @media (max-width: 1280px) {
      .col-heading {
        font-size: 1.7rem;

        .explainer {
          font-size: 0.6rem;
          padding-bottom: 5px !important;
        }
      }
    }

    @media (max-width: 1170px) {
      .col-heading {
        font-size: 1.6rem;

        .explainer {
          font-size: 0.6rem;
          padding-bottom: 5px !important;
        }
      }
    }

  }
  .rating-row {

    display: flex;
    min-height: 8rem;

    .caption {
      height: 120px;
      text-align: left;
      display: flex;
      flex: 0.8;
      align-items: center;
      font-size: 0.8rem;
    }

    .ring {
      flex: 0.2;
      justify-content: center;
      position: relative;

      .ring-chart {
        max-width: 100px;
      }

      .score {
        width: 100px;
        height: 120px;
        position: absolute;
        //padding-top: 29%;
        //padding-left:29%;
        font-size: 10vw;
        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
          font-family: $font-family-base;
          font-weight: 600 !important;

          &.highest {
            color: $p4;
          }

          &.lowest {
            color: $p2;
          }

          &.average {
            color: $clOrange;
          }

        }
      }
    }
  }
  @media (max-width: 1270px) {
    .rating-row {
      .caption {
        font-size: 0.7rem !important;
      }
    }
  }
  @media (max-width: 1170px) {
    .rating-row {
      .caption {
        font-size: 0.6rem !important;
      }
    }
  }

}

.report-heading, .report-heading-with-page-break {
  font-family: $font-family-base;
  font-size: 2.2rem;
  //padding: 1rem 0;
  margin: 2rem 0;
  border-radius: 0 2em;
}

.radar-container {
  display: "flex"
}
.report-chart {
  width:50%;
  display: block;
  margin: auto;
  //min-height: 400px;

}

.report-subheading {
  font-weight: bold;
  font-size: 18pt;
  padding-top: 10px;
  float: left;
  width: 50%;
  text-align: center;
}

.detail-box {
  border: 1px #000 solid;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:nth-of-type(even) {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  p {
    margin-bottom: 0;
  }
  .detail-header {
    font-weight: 500;
  }

}

.analysis-table {
  .row {
    width: 100%;
  }

  .col-heading {
    font-family: $font-family-hypatia;
    font-size: 1.4rem;
    letter-spacing: -0.1rem;
    display: flex;
    align-items: flex-start;
    flex: 0.08;
  }

  .lead-stat {
    font-family: $font-family-base;
    font-size: 2rem;
    font-weight: 600 !important;

    display: flex;
    align-items: flex-start;
    flex: 0.08;

    &.score {
      &.low {
        color: $clRed;
      }
      &.med {
        color: $clOrange;
      }
      &.high {
        color: $clGreen;
      }
    }
    &.range {
    }
  }

  .col-bar {
    flex:0.76;
  }

  .bar-container {

    .bar {
      margin: 2rem 0;
      height: 5px;

      .gauge {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        .low {
          background-color: $clRed;
          flex: 0.2;
        }
        .med {
          background-color: $clOrange;
          flex: 0.5;
        }
        .high {
          background-color: $clGreen;
          flex: 0.3;
        }
      }

      .markers {
        z-index: 2;
        position: relative;
        width: auto;
        display: flex;
        flex-direction: row;
        margin-top: -2rem;
        align-items: center;

        .marker-container {
          display: flex;
          flex-direction: row;

          &.low{
            justify-content: flex-end;
          }
          &.high{
            justify-content: flex-start;
          }
          &.range{
            height: 20px;

          }

          .number {
            position:relative;
            font-family: $font-family-base;
            font-size: 1.4rem;
            font-weight: 600 !important;
            border-radius: 25%;
            background-color: $white;
            border: 1px $lightGrey solid;
            padding: 5px 10px;
            box-shadow: 0 10px 20px 0 $shadowColor;

            &.low {
              color: $clRed;
              left: 25px;
              z-index: 20;
            }
            &.high {
              color: $clGreen;
              right: 25px;
              z-index: 21;
            }
          }
          .range {
            height: 100%;
            width: 100%;
            background-color: $clOrange;
          }

        }

      }
    }
  }
}

/* team presentation */

.presentation-content-container {
  color: inherit;

  &.p1 {
    border-left: 40px solid $p1;
  }
  &.p2 {
    border-left: 40px solid $p2;
  }
  &.p3 {
    border-left: 40px solid $p3;
  }
  &.p4 {
    border-left: 40px solid $p4;
  }
  &.p5 {
    border-left: 40px solid $p5;
  }
  &.p6 {
    border-left: 40px solid $p6;
  }

  .rating-col {
    .col-heading {
      padding-bottom: 10px;
    }
    .rating-row {
      min-height: 0rem;
      .caption {
        font-size: 0.8rem;
      }
    }
  }

  @media (max-width: 1270px) {
    .rating-row {
      .caption {
        font-size: 0.7rem !important;
      }
    }
  }
  @media (max-width: 1170px) {
    .rating-row {
      .caption {
        font-size: 0.6rem !important;
      }
    }
  }

}

.page-title-container {
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  flex-shrink: 0;
  padding-top: 20px;
  width: 100% !important;
  justify-content: space-between;

  &::before {
    content: "";
    background: $p1;
    position: absolute;
    top: 0;
    left: 0;
    width: 20%;
    height: 10px;
  }

  h1 {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1em;
  }

  &.p1 {
    color: $p1;
    &::before {
      background: $p1;
    }
  }
  &.p2 {
    color: $p2;
    &::before {
      background: $p2;
    }
  }
  &.p3 {
    color: $p3;
    &::before {
      background: $p3;
    }
  }
  &.p4 {
    color: $p4;
    &::before {
      background: $p4;
    }
  }
  &.p5 {
    color: $p5;
    &::before {
      background: $p5;
    }
  }
  &.p6 {
    color: $p6;
    &::before {
      background: $p6;
    }
  }

  .page-header-logo {
    width: 150px;
    height: 66px;
    position: relative;
    top:-10px;
  }
}

.team-presentation-card {
  display: flex;
  flex-direction: row;
  //border-radius: 15px;
  padding: 20px;
  color: $reportText !important;


  //&.essence-1 {
  //  background-color: $clOrange;
  //}
  //&.essence-2 {
  //  background-color: $reportText;
  //}
  //&.essence-3 {
  //  background-color: $clPurple;
  //}

  .icon-col {
    flex: 0.1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 2rem;

    .card-icon {
      min-width: 5rem;
      max-width: 5rem;
    }

  }
  .content-col {
    flex: 0.9;
    font-family: $font-family-base;
    color: $white !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .presentation-card-content {
      font-family: $font-family-base;
      font-weight: 600;
      color: $reportText;

      &.p1 {
        color: $p1;
      }
      &.p2 {
        color: $p2;
      }
      &.p3 {
        color: $p3;
      }
      &.p4 {
        color: $p4;
      }
      &.p5 {
        color: $p5;
      }
      &.p6 {
        color: $p6;
      }

      &.smaller {
        font-size: 0.8rem;
      }

      &.action-plan {
        margin-bottom: 10px;
        &::after {
          content: "";
          background: $p1;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 20%;
          height: 5px;
          padding-bottom: 5px;
        }
      }
    }
  }
}

.groundrules {
  display: flex;
  flex-direction: row;

  h4 {
    font-weight: 600;
  }
  ul {

    list-style: none;

    li {
      font-family: $font-family-base;
      font-size: 1rem;

      &::before {
        content: "\2024";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: red; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
        font-size: 2rem;
        line-height: 1.2em;
      }
    }

    &.p1 {
      li {
        &::before {
          color: $p1;
        }
      }
    }
    &.p2 {
      li {
        &::before {
          color: $p2;
        }
      }
    }
    &.p3 {
      li {
        &::before {
          color: $p3;
        }
      }
    }
    &.p4 {
      li {
        &::before {
          color: $p4;
        }
      }
    }
    &.p5 {
      li {
        &::before {
          color: $p5;
        }
      }
    }
    &.p6 {
      li {
        &::before {
          color: $p6;
        }
      }
    }
  }
}

.agendas {
  display: flex;
  flex-direction: row;

  .session-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-of-type {
      border-right: 1px $clGrey dotted;
      padding-right: 2em;
    }
    &:last-of-type {
      padding-left: 2em;
    }

    .hour-icon {
      width: 150px;
    }

    .session-agenda-header {
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}

.gbi-col-container {
  display: flex;
  flex-direction: column;
  font-size: 0.7rem;
}
.gbi-col {
  flex: 0.5;

  //&:first-of-type {
    // margin-right: 1rem;
  //}

  //&:last-of-type {
  //  page-break-before: always !important;
  //}
}

.thank-you {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  //width: 100%;
  //flex-shrink: 0;
  //flex-basis: 100%;
  h1 {
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }
}

.copyright-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 0;
  .copyright {
    font-size: 0.5rem;
    margin-bottom: 0;
    margin-top: 10px;
  }
}

.summary-input-container {
  margin-top: 2rem;

  //.form-control {
  //  background: transparent !important;
  //}
  .summary-input {
    border: none;
    outline: none;
  }
}

@media print {

  * {
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important;  /*Firefox*/
  }

  html {
    width: 1070px;
  }

  html > body
  {
    width: 100%;
    margin: 0mm;
    background-color: $white !important;
  }

  .report-page {
    margin-left:0;
    margin-right:0;
    float: none;
  }

  .no-print, .navbar
  {
    display: none !important;
    padding: 0 !important;
  }
  .sidenav-content
  {
    padding: 0 !important;
  }
  .page-break, .report-heading-with-page-break
  {
    page-break-before: always !important;
  }
  .report-chart
  {
    //width: 400px !important;
  }
  .lead-stat {
    max-width: 100px;
  }

  .report-page .form-switch {
    display: none;
  }

  .toast {
    display: none !important;
  }

  .summary-input-container {
    max-height: 300px;

    .summary-input-content {
      padding: 20px 0 !important;
      margin-top: 40px !important;
    }
    .form-control {
      background: transparent !important;
    }
    .label {
      font-weight: 900 !important;
      font-size: 20px;
      text-transform: uppercase;
      padding: 0;
      left: -8px !important;
    }
    .form-notch {
      display:none !important;
    }
    .summary-input {
      resize: none;
      border: none;
      outline: none;
    }
  }

  .answer h2 {
    margin-top: -3px !important;
  }

  //.form-check-label {
  //  display: none !important;
  //}
}


@media print and (orientation: landscape) {
  body {
    margin: 0;
  }

  html {
    width: 1100px;
  }

  .team-presentation-landscape-page {
    display: flex;
    flex-direction: column;
    height: 100vh;

    div.image {
      flex: 1 1 auto;
      overflow: hidden;
      text-align: center;

      img {
        width: auto;
        max-width: 100%;
        max-height: 100%;
      }

    }

    .page-break, .report-heading-with-page-break
    {
      page-break-before: always !important;
    }

  }
}
